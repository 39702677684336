
<template>
    <div>
        <div style="display: flex;">
            <v-select v-model="select_delete" :options="column_name"
                style="width: 200px; z-index: 1000; margin-top: 4%; margin: 10px" placeholder="Delete Column" />
            <v-select v-model="selected_column" :options="column_add"
                style="width: 200px; z-index: 1000; margin-top: 4%; margin: 10px" placeholder="Add Column" />
        </div>
        <div  id="example-table-theme"></div>
    </div>
</template>
    <script>
import { TabulatorFull as Tabulator } from 'tabulator-tables';
import axios from "axios";
import constants from "./../../../constants.json";
import vSelect from "vue-select";

export default {
    components: {
        "v-select": vSelect,
    },
    name: 'Test',
    data() {
        return {
            valuesLookup: false,
            listed: "",
            HdfcData: [],
            selected_column: null,
            select_delete: null,
            tabulator: null,
            column_add: ["Person Name", "Email", "Course", "Identity", "Mobile"],
            column_name: [
                "Person Name", "Email", "Course", "Identity", "Mobile"
            ],
            headers: [
                { title: "Person Name", field: "person_name", headerFilter: "input", hozAlign: "center" },
                { title: "Email", field: "email", headerFilter: "input", hozAlign: "center" },
                { title: "Course", field: "course", headerFilter: "list", headerFilterParams: { valuesLookup: true, clearable: true } },
                { title: "Identity", field: "identity", hozAlign: "center" },
                { title: "Mobile", field: "mobile", hozAlign: "center" },
                { title: "City", field: "city", hozAlign: "center", headerFilter: "list", headerFilterParams: { valuesLookup: true, clearable: true } },
                { title: "Type ", field: "type", hozAlign: "center" },
                { title: "Mode", field: "mode", hozAlign: "center", headerFilter: "list", headerFilterParams: { valuesLookup: true, clearable: true } },
                { title: "Transaction Amount", field: "transaction_amount", bottomCalc: "sum", hozAlign: "center", },
                { title: "Transaction Date", field: "transaction_date", hozAlign: "center" },
            ],

        };
    },
    watch: {
        selected_column(val) {
            if (val == 'Course') {
                this.valuesLookup = true
                this.listed = "list"
            }
            this.addColumn(val)
        },
        select_delete(val) {
            this.deleteColumn(val)
        }
    },
    mounted() {
        this.getHdfcReceiptsData()
        // this.tabulator = new Tabulator(this.$refs.table, {
        //     data: this.HdfcData,
        // rowFormatter: (function(row) {
        //   var evenColor = "#F5F6FA";
        //   var oddColor = "#FFF";
        //   row.getElement().style.backgroundColor = row._row.position % 2 === 0 ? evenColor : oddColor;
        //   }),
        //     columns: this.headers,
        //     debugInvalidOptions: false,
        // });
    },
    methods: {
        getHdfcReceiptsData() {

            this.$vs.loading({
                type: 'default',
            })
            let obj = {
                // getHdfcReceiotsDataWithDateAndTime?date=1661970600&mobile=&email=&transaction_id=&name=
                // date : this.humandateToEpochconveter(this.dates),
                // mobile: this.search_by_mobile,
                // email: this.search_by_email,
                // transaction_id: this.search_by_trans_id,
                // name: this.search_by_name
                date: 1661970600,
                mobile: "",
                email: "",
                transaction_id: "",
                name: ""

            }
            let url = `${constants.SERVER_API}getHdfcReceiotsDataWithDateAndTime`;
            axios
                .get(url, {
                    params: obj,
                    headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
                })
                .then((response) => {
                    this.HdfcData = response.data.data;
                    console.log("this.HdfcData", this.HdfcData);
                    this.$vs.loading.close();
                    this.tabulator = new Tabulator("#example-table-theme", {
                        data: this.HdfcData,
                        rowFormatter: (function(row) {
                        var evenColor = "#F5F6FA";
                        var oddColor = "#FFF";
                        row.getElement().style.backgroundColor = row._row.position % 2 === 0 ? evenColor : oddColor;
                        }),
                        columns: this.headers,
                        debugInvalidOptions: false,
                    });
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        addColumn(val) {
            const feild_name = {
                "Person Name": "person_name",
                "Email": "email",
                "Course": "course",
                "Identity": "identity",
                "Mobile": "mobile"
            }
            console.log("this.valuesLookup", this.valuesLookup)
            this.tabulator.addColumn({ title: val, field: feild_name[val], headerFilter: this.listed, headerFilterParams: { valuesLookup: `${this.valuesLookup}`, clearable: `${this.valuesLookup}` } })
                .then(function (column) {
                    //column - the component for the newly created column
                    console.log("success", column)
                    //run code after column has been added
                })
                .catch(function (error) {
                    console.log("Error", error)
                    //handle error adding column
                });
        },
        deleteColumn(val) {
            const feild_name = {
                "Person Name": "person_name",
                "Email": "email",
                "Course": "course",
                "Identity": "identity",
                "Mobile": "mobile"
            }
            this.tabulator.deleteColumn(feild_name[val])
        }
    },
};
</script>
    <style >
    /* .tabulator .tabulator-header {
            background-color: #f2f2f2;
        }
        
        .tabulator .tabulator-row:nth-child(odd) {
            background-color: #f2f2f2;
            color: rgb(0, 0, 0) !important;
        }
        
        .tabulator .tabulator-row:nth-child(even) {
            background-color: #e4e1e1;
            color: rgb(0, 0, 0) !important;
        }
        #example-table-theme{
        background-color:#ccc;
        border: 1px solid #333;
        border-radius: 10px;
    } */
    
    /*Theme the header*/
    #example-table-theme .tabulator-header {
        background-color: rgb(235, 114, 114);
        color: #fff;
    }
    
    /*Allow column header names to wrap lines*/
    #example-table-theme .tabulator-header .tabulator-col,
    #example-table-theme .tabulator-header .tabulator-col-row-handle {
        white-space: normal;
    }
    
    /*Color the table rows*/
    #example-table-theme .tabulator-tableHolder .tabulator-table .tabulator-row {
        color: #fff;
        background-color: rgb(214, 23, 23);
    }
    
    /*Color even rows*/
    #example-table-theme .tabulator-tableHolder .tabulator-table .tabulator-row:nth-child(even) {
        color: #fff;
        background-color: rgb(214, 23, 23);
    }
    </style>